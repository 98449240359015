<template>
    <div
        class="relative dropdown-menu"
        :class="{
            'dropdown-menu--right-align': right,
            'dropdown-menu--disabled': disabled,
        }"
        v-on-click-outside="onClickOutside"
    >
        <div @click="onClick()">
            <Loader :size="4" class="mr-2" v-if="loading" />
            <slot><DotsButton /></slot>
        </div>
        <!-- Dropdown menu -->
        <div
            class="dropdown-menu__list z-20 bg-white divide-y divide-gray-100 rounded-lg shadow-lg overflow-y-auto absolute"
            :class="{ hidden: !show }"
        >
            <ul class="py-1 text-sm">
                <li
                    v-for="option in options"
                    :key="option.id"
                    class="w-full"
                >
                    <div
                        class="dropdown-menu__list-item w-full px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
                        :class="{
                            'dropdown-menu__list-item--disabled': option.disabled,
                        }"
                        @click="onOptionClicked(option)"
                    >
                        <component
                            v-if="option.icon"
                            :is="option.icon"
                            class="fill-current"
                            :class="{
                                [option.iconClass]: !option.disabled,
                            }"
                        />
                        <span
                            v-if="option.label"
                            class="text-gray-900"
                            :class="option.labelClass"
                        >
                            {{ option.label }}
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import DotsButton from '@shared/components/buttons/VerticalDotsButton.vue';
const Loader = () => import("@shared/loader");

export default {
    name: "DropdownMenu",
    components: {
        DotsButton,
        Loader,
    },
    data() {
        return {
            show: false,
        };
    },
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
        right: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onClick() {
            if (this.disabled) return;
            this.show = !this.show;
        },
        onClickOutside() {
            this.show = false;
        },
        onOptionClicked(item) {
            if (!this.disabled && !item.disabled) this.$emit("select", item.value);
            this.onClickOutside();
        },
    },
};
</script>

<style lang="scss" scoped>
.dropdown-menu {
    &__list-item {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        &--disabled {
            @apply opacity-80 text-gray-600;
            cursor: not-allowed;
        }
    }
    &--right-align & {
        &__list {
            right: 0;
        }
    }
    &--disabled {
        cursor: not-allowed;
    }
}
.loader {
    border-color: #ffffff !important;
    border-bottom-color: #0d69d5 !important;
    border-width: 3px !important;
}
</style>
