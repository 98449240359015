<template>
  <BaseButton
    class="remove-button"
    v-on="$listeners"
    v-bind="$attrs"
    :disabled="disabled"
  >
    <TrashIcon
      class="fill-current"
      :class="{
        'text-grey': disabled,
        'text-red-500': !disabled,
      }"
    />
  </BaseButton>
</template>

<script>
import BaseButton from './BaseButton.vue';
import TrashIcon from '@shared/assets/trash.svg';

export default {
  components: {
    BaseButton,
    TrashIcon,
  },
  props: {
    disabled: Boolean,
  }
}
</script>

<style lang="scss" scoped>
.remove-button {
  padding-inline: 8px !important;
}
</style>