<template>
  <BaseButton
    class="add-button text-white bg-primary rounded-md"
    v-on="$listeners"
    v-bind="$attrs"
  >
    Add
  </BaseButton>
</template>

<script>
import BaseButton from '@shared/components/buttons/BaseButton.vue';

export default {
  components: {
    BaseButton,
  },
}
</script>

<style lang="scss" scoped>
.add-button {
  padding-inline: 10px !important;
  min-height: 40px !important;
}
</style>