<template>
  <BaseButton
    class="edit-button"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <EditIcon
      class="fill-current"
    />
    <span class="text-base-content font-normal">
      <slot />
    </span>
  </BaseButton>
</template>

<script>
import BaseButton from './BaseButton.vue';
import EditIcon from '@shared/assets/edit-pencil-alt.svg';

export default {
  components: {
    BaseButton,
    EditIcon,
  },
}
</script>

<style lang="scss" scoped>
.edit-button {
  padding-inline: 5px !important;
}
</style>