<template>
  <span
    class="btn btn-ghost flex-shrink-0 no-print hidden-padding"
    v-bind="$attrs"
  >
    <svg
      class="transition-all svgCollAll print--none fill-current"
      :class="{
        'transform -rotate-90': expanded,
        'transform rotate-90': !expanded && !side,
      }"
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="#0D69D5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.847628 0.222752C0.699168 0.371088 0.624999 0.546925 0.625 0.750097L0.625 11.2499C0.625 11.4532 0.699168 11.6289 0.847628 11.7774C0.996252 11.9258 1.17197 12 1.3751 12C1.57819 12 1.7539 11.9258 1.9024 11.7774L7.15235 6.52743C7.30081 6.3788 7.37514 6.20309 7.37514 6C7.37514 5.79691 7.30081 5.62103 7.15235 5.47269L1.90236 0.222752C1.7539 0.0744552 1.57819 -4.16652e-08 1.37506 -3.2786e-08C1.17197 -2.39086e-08 0.996252 0.0744552 0.847628 0.222752Z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    // use to chnage view in collapsed state
    side: Boolean,
    expanded: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="scss" scoped>

</style>