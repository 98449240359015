<template>
  <BaseButton
    class="vdots-button"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <svg
      class="fill-current"
      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="#0D69D5"
    >
      <path d="M10.0002 2.5C9.0835 2.5 8.3335 3.25 8.3335 4.16667C8.3335 5.08333 9.0835 5.83333 10.0002 5.83333C10.9168 5.83333 11.6668 5.08333 11.6668 4.16667C11.6668 3.25 10.9168 2.5 10.0002 2.5ZM10.0002 14.1667C9.0835 14.1667 8.3335 14.9167 8.3335 15.8333C8.3335 16.75 9.0835 17.5 10.0002 17.5C10.9168 17.5 11.6668 16.75 11.6668 15.8333C11.6668 14.9167 10.9168 14.1667 10.0002 14.1667ZM10.0002 8.33333C9.0835 8.33333 8.3335 9.08333 8.3335 10C8.3335 10.9167 9.0835 11.6667 10.0002 11.6667C10.9168 11.6667 11.6668 10.9167 11.6668 10C11.6668 9.08333 10.9168 8.33333 10.0002 8.33333Z"/>
    </svg>
  </BaseButton>
</template>

<script>
import BaseButton from './BaseButton.vue';

export default {
  components: {
    BaseButton,
  },
}
</script>

<style lang="scss" scoped>
</style>