<template>
  <button
    class="btn btn-ghost base-button flex-shrink-0 no-print border"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span class="base-button__content">
      <slot />
    </span>
  </button>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.base-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-inline: 12px;
  height: unset;
  min-height: 32px;
  text-transform: none;
  &__content {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
  }
}
</style>