var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"on-click-outside",rawName:"v-on-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"relative dropdown-menu",class:{
        'dropdown-menu--right-align': _vm.right,
        'dropdown-menu--disabled': _vm.disabled,
    }},[_c('div',{on:{"click":function($event){return _vm.onClick()}}},[(_vm.loading)?_c('Loader',{staticClass:"mr-2",attrs:{"size":4}}):_vm._e(),_vm._t("default",function(){return [_c('DotsButton')]})],2),_c('div',{staticClass:"dropdown-menu__list z-20 bg-white divide-y divide-gray-100 rounded-lg shadow-lg overflow-y-auto absolute",class:{ hidden: !_vm.show }},[_c('ul',{staticClass:"py-1 text-sm"},_vm._l((_vm.options),function(option){return _c('li',{key:option.id,staticClass:"w-full"},[_c('div',{staticClass:"dropdown-menu__list-item w-full px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center",class:{
                        'dropdown-menu__list-item--disabled': option.disabled,
                    },on:{"click":function($event){return _vm.onOptionClicked(option)}}},[(option.icon)?_c(option.icon,{tag:"component",staticClass:"fill-current",class:{
                            [option.iconClass]: !option.disabled,
                        }}):_vm._e(),(option.label)?_c('span',{staticClass:"text-gray-900",class:option.labelClass},[_vm._v(" "+_vm._s(option.label)+" ")]):_vm._e()],1)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }